import React, { useState, useEffect } from 'react';
import { Wheel } from 'react-custom-roulette'
import pointerImage from './img/pointer.png'; // Import the pointer image
import './css/styles.css'; // Import the CSS file
import Modal from './Modal'; // Adjust the path


const RouletteComponent = ({ data, prize, setPrize, prizenumber}) => {
   
  const [mustSpin, setMustSpin] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false); // New state for button clicked
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {}, [data]);
  const handleSpinClick = () => {
    if (!mustSpin) {
      setMustSpin(true);
      setButtonClicked(true); // Set button clicked to true
      setShowModal(false); // Close the modal when spinning starts
    }
  }

  const handleStopSpinning = () => {
    setShowModal(true);
    setPrize(prize);
    setMustSpin(false);
  }

  const closeModal = () => {
    setShowModal(false);
  }

  return (
    <>
     <div className="container">
      {!buttonClicked && (
        <button
          onClick={handleSpinClick}
          className="play-button"
        >
          Çevir
        </button>
      )}
     <div className="wheel">
    <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizenumber}
        data={data}
        onStopSpinning={handleStopSpinning} // Show modal on stop spinning


        pointerProps={{
          src: pointerImage,
          style: {
            transform: 'translate(-30%, 30%) rotate(45deg)', // Example styling
          },
        }}

        backgroundColors={['#e7ca6a', '#e9b712']}
        textColors={['#ffffff']}
        innerRadius={0}
        fontFamily={'Poppins'}
        fontSize={15}
        disableInitialAnimation={false}
        perpendicularText={false}
        textDistance={53}
      />

      </div>

      </div>
      <Modal isOpen={showModal} onClose={closeModal} prize={prize} />

    </>

    
  )
  
}
export default RouletteComponent;
