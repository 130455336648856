// Modal.js

import React from 'react';
import './css/modal.css';

const Modal = ({ isOpen, onClose, prize }) => {
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <h2>Çıkan Ödül:</h2>
        <p>{prize}</p>
      </div>
    </div>
  );
};

export default Modal;
