import React, { useState } from 'react';
import axios from 'axios'; // Import the axios library
import RouletteComponent from './RouletteComponent'; // Import the RouletteComponent
import './css/App.css'; // Import your app's CSS file
import config from './config';

const App = () => {
  const [username, setUsername] = useState('');
  const [ticket, setTicket] = useState('');
  const [showRoulette, setShowRoulette] = useState(false);
  const [rouletteData, setRouletteData] = useState([]); // State to hold the data from the endpoint
  const [prize, setPrize] = useState(''); // State for prize
  const [prizenumber, setPrizenumber] = useState(''); // State for prize

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false); // Add loading state


  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    }

  const handleTicketChange = (event) => {
    setTicket(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    try {
      const response = await axios.post(config.apiEndpoint, {
        username: username,
        ticket: ticket
      });

      if (response.data.status === 'yes') {
        setShowRoulette(true);
        setRouletteData(response.data.data);
        setPrize(response.data.prize); // Set the prize from the response
        setPrizenumber(response.data.prizenumber);
        setErrorMessage(''); // Clear error message
      } else {
        setShowRoulette(false);
        setRouletteData([]); // Reset the data
        setPrize(''); // Reset the prize
        setErrorMessage(response.data.message); // Set error message


      }
    } catch (error) {
      console.error('Error:', error);
      setShowRoulette(false);
      setRouletteData([]); // Reset the data
      setPrize(''); // Reset the prize
      setErrorMessage('Server Error'); // Set error message


    }finally {
      setLoading(false); // Stop loading after request completes
    }
  }

  

  return (
    <div className="App">
      <div className="centered-content">
        {!showRoulette ? (
          <div className="giris">
          <form className="username-form" onSubmit={handleSubmit}>
            <label>
              Kullanıcı Adını Gir:
            </label>
            <input
                type="text"
                value={username}
                onChange={handleUsernameChange}
                className='input'
              required/>
            <label>
              Biletini Gir:
            </label>
            <input
                type="text"
                value={ticket}
                onChange={handleTicketChange}
                className='input'
              required/>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button className='confirm-button' type="submit" disabled={isLoading}>
            {isLoading ? 'Bekleyin...' : 'Oyna'}
          </button>          
          </form>
          </div>
        ) : (
          <RouletteComponent data={rouletteData} prize={prize} setPrize={setPrize} prizenumber={prizenumber} />
        )}
      </div>
    </div>
  );
}

export default App;
